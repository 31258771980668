import { graphql } from 'gatsby'
import React from 'react'
import { Container, ItemImage, PaginationList } from '../components'
import Layout from '../layouts'
import moment from 'moment'
import styled from '@emotion/styled'
import 'moment/locale/ru'
import { useTranslation } from '.'
import { theme } from '../assets/theme'
import ReactMarkdown from 'react-markdown'
moment.locale('ru')

const Article = props => {
  const { strapiArticle: item } = props.data
  const { otherArticles, locale, meta } = props.pageContext
  const { t } = useTranslation(locale)
  const from = {
    title: t('Все новости'),
    path: `/${locale}/news`,
  }

  const str = item.description.replace(
    /\/uploads\//,
    'https://admin.afonpodvorie.com/uploads/',
  )

  return (
    <Layout
      meta={meta}
      from={from}
      locale={props?.pageContext?.locale}
      location={props?.location}
      menu={props?.pageContext?.menu}>
      {item ? (
        <>
          <ViewContainer>
            <ViewInfo>
              <h1>{item?.title}</h1>
              <span>
                {moment(item.created_at).format('DD MMMM YYYY года, dddd')}
              </span>
              <ItemImage
                image={item.image.localFile.childImageSharp.gatsbyImageData}
                alt={item?.title}
              />
              <SReactMarkdown>{str}</SReactMarkdown>
            </ViewInfo>
          </ViewContainer>
          <PaginationList
            title={t('Похожие новости')}
            entity="articles"
            locale={props?.pageContext.locale}
            items={otherArticles}></PaginationList>
        </>
      ) : (
        ''
      )}
    </Layout>
  )
}

export default Article

export const query = graphql`
  query ArticleTemplate($id: String!) {
    strapiArticle(id: { eq: $id }) {
      id
      title
      created_at
      image {
        localFile {
          childImageSharp {
            gatsbyImageData(width: 564, height: 440)
          }
        }
      }
      description
    }
  }
`

const ViewContainer = styled(Container)`
  display: flex;
  flex-direction: column;
`

const ViewInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 3rem;

  h1 {
    font-size: 2.2rem;
  }
`

export const SReactMarkdown = styled(ReactMarkdown)`
  width: 100%;
  display: flex;
  flex-direction: column;

  h2 {
    font-size: 2.2rem;
    margin: 0;
    margin-bottom: 2rem;
  }

  h6 {
    display: block;
    padding: 30px;
    font-weight: normal;
    background: #cac297;
    line-height: 28px;
  }

  img {
    width: 100%;
  }

  ul {
    list-style-type: none;

    li {
      position: relative;
      line-height: 28px;
      font-size: 18px;
      &:marker {
        content: '';
        display: none;
      }
      &:before {
        content: '';
        display: block;
        position: absolute;
        left: -1.5rem;
        top: 50%;
        transform: translate(0, -50%);
        border-radius: 99999px;
        width: 8px;
        height: 8px;
        background: #cac297;
      }
    }
  }

  blockquote {
    position: relative;
    margin: 30px 0;

    &:before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 10px;
      height: 100%;
      background: #cac297;
    }
    & > p {
      margin: 0;
      margin-left: 2rem;
      font-size: 36px;
      line-height: 46px;

      ${() => theme.mqMax('lg')} {
        font-size: 24px;
        line-height: 30px;
      }
    }
  }
`
