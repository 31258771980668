import { graphql } from 'gatsby'
import React from 'react'
import { Container, ItemImage, PaginationList } from '../components'
import Layout from '../layouts'
import moment from 'moment'
import styled from '@emotion/styled'
import { useTranslation } from '.'
import { SReactMarkdown } from './article'

const HolyPlace = props => {
  const { strapiHolyPlace: item } = props.data
  const { otherHolyPlaces, locale, meta, menu } = props.pageContext
  const str = item.description.replace(
    /\/uploads\//,
    'https://admin.afonpodvorie.com/uploads/',
  )
  const { t } = useTranslation(locale)
  const from = {
    title: t('main-page'),
    path: `/${locale}`,
  }
  return (
    <Layout
      from={from}
      meta={meta}
      locale={locale}
      location={props.location}
      menu={menu}>
      {item ? (
        <>
          <ViewContainer>
            <ViewInfo>
              <h1>{item?.title}</h1>
              <span>
                {moment(item.created_at).format('DD MMMM YYYY года, dddd')}
              </span>
              <ItemImage
                image={item.image.localFile.childImageSharp.gatsbyImageData}
                alt={item?.title}
              />
              <SReactMarkdown>{str}</SReactMarkdown>
            </ViewInfo>
          </ViewContainer>
          <PaginationList
            title={t('Похожие места')}
            entity="holy-places"
            locale={props?.pageContext.locale}
            items={otherHolyPlaces}></PaginationList>
        </>
      ) : (
        ''
      )}
    </Layout>
  )
}

export default HolyPlace

export const query = graphql`
  query HolyPlaceTemplate($id: String!) {
    strapiHolyPlace(id: { eq: $id }) {
      id
      title
      created_at
      image {
        localFile {
          childImageSharp {
            gatsbyImageData(width: 564, height: 440)
          }
        }
      }
      description
    }
  }
`

const ViewContainer = styled(Container)`
  display: flex;
  flex-direction: column;
`

const ViewInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 3rem;

  h1 {
    font-size: 2.2rem;
  }
`
